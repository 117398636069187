











import { Component, Vue, Watch } from 'vue-property-decorator'
import { collection, getDocs, getFirestore, where, query, FieldPath } from '@firebase/firestore'
import { Course } from '@/assets/TimingGateDef'

@Component({ components: {} })
export default class AppBar extends Vue {
  private courses: Course[] = []
  private selected: Course | null = null

  async mounted() {
    const docs = await getDocs(collection(getFirestore(), 'courses'))
    this.courses = docs.docs.map(d => new Course(d.data()))
  }
  private courseName(course: Course) {
    return `${course.code}: ${course.name}`
  }
  @Watch('selected')
  selectionChanged() {
    if (this.selected) {
      const code = this.selected.code
      if (code !== this.$route.params.code) {
        this.$router.push(`/editor/${code}`)
      }
    }
  }
}
