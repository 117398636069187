import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'
// const fadeTransition = createSimpleTransition('v-fade-transition')
// Vue.component('v-fade-transition', fadeTransition)

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#698DEB',
                secondary: '#FF495C',
                tertiary: '#80FF72',
                accent: '#39A0ED',
                error: '#FF495C',
                warning: '#E7C64B',
                info: '#006BFC',
                success: '#00CE15',
                dark: '#000000'
            }
        },
        options: {
            customProperties: true
        }
    }
})
