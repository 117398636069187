import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'

import '@mdi/font/css/materialdesignicons.css'

import { initializeApp } from "firebase/app"

initializeApp({
  apiKey: 'AIzaSyC_l9050DbChg5E_iOMqppW7OscD6Qyav0',
  databaseURL: 'https://microtracksmash.firebaseio.com',
  projectId: 'microtracksmash',
  storageBucket: 'microtracksmash.appspot.com',
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
