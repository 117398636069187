export interface IVector {
  x?: number
  y?: number
  z?: number
}

export enum CourseObject {
  pipe = 'pipe',
  tee = 'tee',
  timingGate = 'timingGate',
  gate = 'gate',
  ladder = 'ladder',
  climber = 'climber',
  pathPoint = 'pathPoint',
  path = 'path',
  panel = 'panel'
}
export enum PipeLength {
  p3 = 3,
  p4 = 5,
  p10 = 10,
  p14 = 14,
  p20 = 20,
}

export interface IObject {
  position: IVector
  rotation?: IVector
}

export interface IPipe extends IObject {
  length?: PipeLength
}

export interface IPanel extends IObject {
  size?: IVector
}
export interface IPath {
  name: string
  points: IVector[]
}

export class Course implements ICourse {
  name: string = ''
  author: string = ''
  code: string = ''
  mtsSets: number = 1
  timingGate: IObject = { position: {} }
  gates: IObject[] = []
  ladders: IObject[] = []
  climbers: IObject[] = []
  pipes: IPipe[] = []
  tees: IObject[] = []
  path: IVector[] = []
  paths: IPath[] = []
  panels: IPanel[] = []

  constructor(init?: Partial<ICourse>) {
    Object.assign(this, init)
  }

}

export interface ICourse {
  name: string
  author: string
  code: string
  mtsSets: number

  timingGate: IObject
  gates: IObject[]
  ladders: IObject[]
  climbers: IObject[]
  pipes: IPipe[]
  tees: IObject[]
  panels: IPanel[]
  path: IVector[]
  paths: IPath[]
}

export const TimingGateDef = {
  pipes: [
    { length: PipeLength.p10, position: { x: -10, y: 0 } },
    { length: PipeLength.p10, position: { x: 10, y: 0 } },
    { length: PipeLength.p20, position: { x: 0, y: 5, z: 20 }, rotation: { z: 90 } },
    { length: PipeLength.p20, position: { x: 0, y: -5, z: 20 }, rotation: { z: 90 } },
    { length: PipeLength.p20, position: { x: -10, y: 5, z: 10 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: -10, y: -5, z: 10 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: 10, y: 5, z: 10 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: 10, y: -5, z: 10 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: -10, y: 5, z: 25 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: 10, y: 5, z: 25 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: -10, y: -5, z: 25 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: 10, y: -5, z: 25 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: -10, y: -5, z: 35 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: 10, y: -5, z: 35 }, rotation: { x: 90 } },
    { length: PipeLength.p10, position: { x: 0, y: -5, z: 40 }, rotation: { z: 90 } },
    { length: PipeLength.p10, position: { x: 0, y: 5, z: 30 }, rotation: { z: 90 } },
    { length: PipeLength.p4, position: { x: -7.5, y: -5, z: 40 }, rotation: { z: 90 } },
    { length: PipeLength.p4, position: { x: 7.5, y: -5, z: 40 }, rotation: { z: 90 } },
    { length: PipeLength.p4, position: { x: -7.5, y: 5, z: 30 }, rotation: { z: 90 } },
    { length: PipeLength.p4, position: { x: 7.5, y: 5, z: 30 }, rotation: { z: 90 } },
    { length: PipeLength.p14, position: { x: -5, y: 0, z: 35 }, rotation: { x: -45 } },
    { length: PipeLength.p14, position: { x: 5, y: 0, z: 35 }, rotation: { x: -45 } }
  ],
  tees: [
    { position: { x: 10, y: -5, z: 0 } },
    { position: { x: 10, y: 5, z: 0 } },
    { position: { x: -10, y: -5, z: 0 } },
    { position: { x: -10, y: 5, z: 0 } },
    { position: { x: 10, y: -5, z: 20 }, rotation: { x: 90 } },
    { position: { x: 10, y: 5, z: 20 }, rotation: { x: 90 } },
    { position: { x: -10, y: -5, z: 20 }, rotation: { x: 90 } },
    { position: { x: -10, y: 5, z: 20 }, rotation: { x: 90 } },
    { position: { x: 10, y: -5, z: 30 }, rotation: { x: 90 } },
    { position: { x: -10, y: -5, z: 30 }, rotation: { x: 90 } },
    { position: { x: 10, y: 5, z: 30 }, rotation: { x: 90 } },
    { position: { x: -10, y: 5, z: 30 }, rotation: { x: 90 } },
    { position: { x: 10, y: -5, z: 40 }, rotation: { x: 90 } },
    { position: { x: -10, y: -5, z: 40 }, rotation: { x: 90 } },
    { position: { x: -5, y: -5, z: 40 }, rotation: { z: 90 } },
    { position: { x: 5, y: -5, z: 40 }, rotation: { z: 90 } },
    { position: { x: -5, y: 5, z: 30 }, rotation: { z: 90 } },
    { position: { x: 5, y: 5, z: 30 }, rotation: { z: 90 } }
  ],
  panels: [
    { position: { x: -10, y: 0, z: 15 }, rotation: { z: 90, y: 90 }, size: { x: 10, y: 30 } },
    { position: { x: 10, y: 0, z: 15 }, rotation: { z: 90, y: 90 }, size: { x: 10, y: 30 } },
    { position: { x: 0, y: -5, z: 30 }, rotation: { x: 90, y: 0 }, size: { x: 20, y: 20 } },
    { position: { x: 0, y: 5, z: 25 }, rotation: { x: 90, y: 0 }, size: { x: 20, y: 10 } },
    { position: { x: 0, y: 0.25, z: 35.5 }, rotation: { x: -45, y: 0 }, size: { x: 20, y: 14.5 } },
  ]
}


export const GateDef = {
  pipes: [
    { length: PipeLength.p20, position: { z: 3 }, rotation: { z: 90 } },
    { length: PipeLength.p20, position: { x: -10, z: 13 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: 10, z: 13 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { z: 23 }, rotation: { z: 90 } },
    { length: PipeLength.p3, position: { x: -10, z: 1.5 }, rotation: { x: 90 } },
    { length: PipeLength.p3, position: { x: 10, z: 1.5 }, rotation: { x: 90 } },
  ],
  tees: [
    { position: { x: -10, z: 0 }, rotation: { x: 0 } },
    { position: { x: -10, z: 3 }, rotation: { x: 90 } },
    { position: { x: -10, z: 23 }, rotation: { x: 90 } },
    { position: { x: 10, z: 0 }, rotation: { x: 0 } },
    { position: { x: 10, z: 3 }, rotation: { x: 90 } },
    { position: { x: 10, z: 23 }, rotation: { x: 90 } }
  ]
}

export const LadderAddDef = {
  pipes: [
    { length: PipeLength.p20, position: { x: -10, z: 33 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: 10, z: 33 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { z: 43 }, rotation: { z: 90 } },
  ],
  tees: [
    { position: { x: -10, z: 43 }, rotation: { x: 90 } },
    { position: { x: 10, z: 43 }, rotation: { x: 90 } }
  ]
}

export const ClimberAddDef = {
  pipes: [
    { length: PipeLength.p20, position: { x: -10, z: 53 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { x: 10, z: 53 }, rotation: { x: 90 } },
    { length: PipeLength.p20, position: { z: 63 }, rotation: { z: 90 } },
  ],
  tees: [
    { position: { x: -10, z: 63 }, rotation: { x: 90 } },
    { position: { x: 10, z: 63 }, rotation: { x: 90 } }
  ]
}
