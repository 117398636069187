









import { Component, Vue } from 'vue-property-decorator'
import AppBar from './components/AppBar.vue'

@Component({ components: { AppBar } })
export default class App extends Vue {
  do() {
    AppBar
  }
}
